import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Dashboard, LinkedIn, Web } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import Signup from "../authentication/Signup";
import Tablausuario from "./TablaUsuario";

const useStyles = makeStyles({
  list: {
    width: 180,
  },
  info: {
    "text-align": "center",
    "font-weight": "bold",
  },
});

const NavDrawerAdmin = (props) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      open={props.drawerOpened}
      onClose={props.toggleDrawer(false)}
    >
      <div
        className={classes.list}
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
      >
        <List>
          <ListItem className={classes.info}>
            <ListItemText primary="Menú" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/Dashboard">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="https://vigalab.com/">
            <ListItemIcon>
              <Web />
            </ListItemIcon>
            <ListItemText primary="Vigalab.com" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            component="a"
            href="https://www.linkedin.com/company/vigalab/"
          >
            <ListItemIcon>
              <LinkedIn />
            </ListItemIcon>
            <ListItemText primary="LinkedIn" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/register">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Registrar Usuarios" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/tablausuario">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Administrar Usuarios" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};
export default NavDrawerAdmin;
