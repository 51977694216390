import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { useHistory } from "react-router-dom";
import "./styles.css";

import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { authenticate } = useContext(AccountContext);
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const onSubmit = (event) => {
    event.preventDefault();

    authenticate(email, password)
      .then((data) => {
        history.push("/Dashboard");
      })
      .catch((err) => {
        console.error("Failed to login", err);
        if (err == "UserNotConfirmedException: User is not confirmed.") {
          window.alert("No ha confirmado email");
        }
        if (err == "NotAuthorizedException: Incorrect username or password.") {
          window.alert("Username o contraseña incorrecta");
        }
        if (err == "InvalidParameterException: Missing required parameter") {
          window.alert("Falta el parámetro obligatorio");
        }
      });
  };

  const paperStyle = {
    padding: 30,
    height: "50vh",
    width: 485,
    margin: "20px auto",
  };
  
  const avatarStyle = { backgroundColor: "#005829" };
  const letrasStyle = { color: "#005829" };
  const btnstyle = { margin: "8px 0", backgroundColor: "#005829" };

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            style={{
              fontWeight: "bold",
              marginTop: "15px",
            }}
          >
            Dashboard CCU
          </Typography>
          <Typography
            style={{
              color: "#58ad2f",
              fontSize: "1em",
              margin: "20px 0",
            }}
          >
            * Para poder ingresar debes solicitar tu ingreso al administrador
          </Typography>
        </Grid>
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          label="Email"
          placeholder="TU EMAIL"
          type="email"
          fullWidth
          required
        />
        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          label="Contraseña"
          placeholder="TU CLAVE"
          type="password"
          fullWidth
          required
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={onSubmit}
        >
          Ingresar
        </Button>
        <Typography>
          <Link href="#" style={letrasStyle}>
            ¿Olvidaste tu Clave?
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Login;
