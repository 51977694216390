import React, { useContext, useState, useEffect } from "react";
import { Account } from "../authentication/Account";
import Status from "../authentication/Status";
import Signup from "../authentication/Signup";
import Login from "../authentication/Login";
import Settings from "../authentication/Settings";
import { AccountContext } from "../authentication/Account";
import { useHistory } from "react-router-dom";
import Dashboard from "../views/Dashboard";

const Main = () => {
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);
  let history = useHistory();

  useEffect(() => {
    getSession().then(() => {
      setLoggedIn(true);
    });
  }, []);

  return (
    <>
      {!loggedIn ? (
        <>
          <Status />
          <Login />
          <Settings />
        </>
      ) : (
        <Dashboard />
      )}
    </>
  );
};

export default Main;
