import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
var moment = require("moment-timezone");

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "id_vibot",
    headerName: "ID Vibot",
    width: 150,
  },
  {
    field: "id_sensor",
    headerName: "ID Sensor",
    width: 150,
  },
  {
    field: "ml_cantidad",
    headerName: "Cantidad ML",
    type: "number",
    width: 170,
  },
  {
    field: "seg_duracion",
    headerName: "Seg. Duración",
    type: "number",
    width: 170,
  },
  {
    field: "hora_inicio",
    headerName: "Fecha",
    width: 170,
    valueFormatter: ({ value }) =>
      moment(value).utc().format("DD/MM/YYYY HH:mm:ss"),
  },
];

const TableCCU = (props) => {
  const { loading, rows } = props;

  return !loading ? (
    <div style={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={40}
        rowsPerPageOptions={[40]}
        density={"compact"}
        //checkboxSelection
        disableSelectionOnClick
      />
    </div>
  ) : (
    "Loading..."
  );
};
export default TableCCU;
