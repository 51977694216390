import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, Brightness4, Brightness7 } from "@material-ui/icons";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { AccountContext } from "../authentication/Account";
import Store from "../context";
import NavDrawer from "./NavDrawer";
import { useHistory } from "react-router-dom";
import NavDrawerAdmin from "./NavDrawerAdmin";
import Button from "@mui/material/Button";
import { ThreeDRotation, ExitToAppOutlined } from "@material-ui/icons";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  root2: {
    color: "white",
  },
  root3: {
    backgroundColor: "#005829",
  },
}));

const NavBar = () => {
  const { state, dispatch } = useContext(Store);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [role, setRole] = useState();
  const [userEmail, setUserEmail] = useState();
  let mode;
  const changeTheme = (mode) => {
    if (state.myTheme === "dark") {
      mode = "light";
    } else {
      mode = "dark";
    }
    dispatch({ type: "THEME", payload: mode });
  };

  const ToggleButton = () => {
    if (state.myTheme === "dark") {
      return <Brightness7 />;
    } else {
      return <Brightness4 />;
    }
  };
  const toggleDrawer = (booleanValue) => () => {
    setDrawerOpened(booleanValue);
  };

  const [status, setStatus] = useState(false);

  //console.log(AccountContext);

  const { getSession, logout } = useContext(AccountContext);

  // console.log(getSession)

  useEffect(() => {
    getSession().then((session) => {
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;
      try {
        setUserEmail(email);
        const [...userType] = payload["cognito:groups"];
        setRole(userType);
      } catch (err) {
        console.log(err);
      }
      setStatus(true);
    });
  }, [getSession]);

  const handleLogout = (e) => {
    //e.preventDefault();
    logout();
    setStatus(false);
    history.push("/");
  };

  let varAux = role != undefined ? role.includes("admin") : false;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.root3}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            CCU
          </Typography>

          <Tooltip title="Toggle light/dark theme">
            <IconButton color="inherit" onClick={() => changeTheme(mode)}>
              <ToggleButton />
            </IconButton>
          </Tooltip>

          {userEmail}

          <Button
            onClick={handleLogout}
            className={`colorBoton`}
            size="large"
            startIcon={<ExitToAppOutlined />}
          >
            Salir
          </Button>
        </Toolbar>
      </AppBar>
      {varAux ? (
        <NavDrawerAdmin
          drawerOpened={drawerOpened}
          toggleDrawer={toggleDrawer}
        />
      ) : (
        <NavDrawer drawerOpened={drawerOpened} toggleDrawer={toggleDrawer} />
      )}
    </div>
  );
};
export default NavBar;
