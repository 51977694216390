import React, { useContext, useReducer, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Account } from "./authentication/Account";
import Store from "./context";
import reducer from "./reducer";
import { usePersistedContext, usePersistedReducer } from "./usePersist";

// Metarial-UI Theme(Dark or Light)
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme, CssBaseline, Container } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./components/Main";
import Dashboard from "./views/Dashboard";
import { AccountContext } from "./authentication/Account";
import Pool from "./UserPool";

import { createStore } from "redux";
import { Provider, useDispatch } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import Signup from "./authentication/Signup";
import ForgotPassword from "./authentication/ForgotPassword";
import Tablausuario from "./components/TablaUsuario";

const App = () => {
  const user = Pool.getCurrentUser();
  const globalStore = usePersistedContext(useContext(Store), "state");

  const [state, dispatch] = usePersistedReducer(
    useReducer(reducer, globalStore),
    "state"
  );

  const theme = createMuiTheme({
    palette: {
      type: state.myTheme, // "light" or "dark"
    },
  });

  // store
  const store = createStore(rootReducer, composeWithDevTools());

  return (
    <Router>
      <Container>
        <Provider store={store}>
          <Store.Provider value={{ state, dispatch }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Account>
                <Switch>
                  <Route path="/Dashboard">
                    <Dashboard />
                  </Route>
                  <Route exact path="/register" component={Signup} />
                  <Route
                    exact
                    path="/recuperacontrasena"
                    component={ForgotPassword}
                  />
                  <Route exact path="/tablausuario" component={Tablausuario} />
                  <Route path="/">
                    <Main />
                  </Route>
                </Switch>
              </Account>
            </ThemeProvider>
          </Store.Provider>
        </Provider>
      </Container>
    </Router>
  );
};
ReactDOM.render(<App />, document.querySelector("#root"));
