import React, { useState, useContext, useEffect } from "react";
import Main from "../components/Main";
import NavBar from "../components/NavBar";
import UserPool from "../UserPool";
import { AccountContext } from "../authentication/Account";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Checkbox from "@material-ui/core/Checkbox";
import Dashboard from "../views/Dashboard";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState();
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    getSession().then((session) => {
      
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;
      try {
       
       const [...userType] = payload["cognito:groups"];
       
        setRole(userType);
      } catch (err) {
        console.log(err);
      }

      setLoggedIn(true);
    });
  }, [loggedIn, getSession]);

  const onSubmit = (event) => {
    event.preventDefault();

    UserPool.signUp(
      email,
      password,
      [{ Name: "email", Value: email }],
      null,
      (err, data) => {
        if (
          err ==
          "InvalidPasswordException: Password did not conform with policy: Password must have uppercase characters"
        ) {
          window.alert("Password must have uppercase characters");
        }
        if (
          err ==
          "InvalidPasswordException: Password did not conform with policy: Password not long enough"
        ) {
          window.alert("Password not long enough");
        }
        if (
          err ==
          "InvalidPasswordException: Password did not conform with policy: Password must have uppercase characters"
        ) {
          window.alert("Password must have uppercase characters");
        }
        if (
          err ==
          "InvalidPasswordException: Password did not conform with policy: Password must have symbol characters"
        ) {
          window.alert("Password must have symbol characters");
        }
        if (
          err ==
          "InvalidPasswordException: Password did not conform with policy: Password must have numeric characters"
        ) {
          window.alert("Password must have numeric characters");
        }
        if (err == "UsernameExistsException: User already exists") {
          window.alert("Usuario ya existe");
        }

        if (data != null) {
          window.alert("registro exitoso. Confirmar email");
          setEmail("");
          setPassword("");
        }
      }
    );
  };

  const paperStyle = {
    padding: 20,
    height: "42vh",
    width: 280,
    margin: "20px auto",
  };
  
  const avatarStyle = { backgroundColor: "#005829" };
  const btnstyle = { margin: "8px 0", backgroundColor: "#005829" };
  let varAux = role != undefined ? role.includes("admin") : false;

  return (
    <>
      {loggedIn && varAux ? (
        <>
          <NavBar />
          <Grid>
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center">
                <Avatar style={avatarStyle}>
                  <AddCircleIcon />
                </Avatar>
                <h2>Registar un nuevo usuario</h2>
              </Grid>
              <TextField
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                label="Email"
                placeholder="Ingresar email"
                type="email"
                fullWidth
                required
              />
              <TextField
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                label="Contraseña"
                placeholder="Ingresar contraseña"
                type="password"
                fullWidth
                required
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={btnstyle}
                fullWidth
                onClick={onSubmit}
              >
                Registrar
              </Button>
            </Paper>
          </Grid>
        </>
      ) : (
        <Dashboard />
      )}
    </>

    /*  ) : (
        <Main />
      )}
 */
  );
};

export default Signup;
