import { CognitoUserPool } from "amazon-cognito-identity-js";

console.log(`${process.env.REACT_APP_USER_POOL_ID}`);
console.log(`${process.env.REACT_APP_API_KEY}`);
console.log(`${process.env.REACT_APP_CLIENT_ID}`);

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

export default new CognitoUserPool(poolData);
